* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Segoe UI";

}

input::placeholder{
  font-size: 14px;
}
input{
  font-size: 14px;
}
html {
  font-size: 62.5%;
}
.modal-content{
  border-radius:15px;
   border:10px solid #fb9120;
   width: 500px;
   margin-left: 433px;
   margin-top: 50px;
   z-index: 99;
}
.brand-list-style{
  padding:6px;
  /* padding-left: 1px; */
  width: 160px;
  text-align: "left";
  padding-left: 30px;
  background-color: white;
  font-family: "Segoe UI";
  /* list-style-type: none; */
  /* color: rgba(69, 74, 84, 0.7); */
  /* border-bottom: 1px solid rgba(69, 74, 84, 0.7); */
  color:rgb(61, 58, 58);
  font-size: 14px;
  font-weight: 500;
  z-index: 1;
  margin-bottom: 0px;
}
.brand-list-style:hover{
  background-color: #a7a29e;
  color: aliceblue;
  cursor: pointer;
}
.modal-title{
  color:white;background-color:#fb9120;text-align:center;padding: 5px;
}
.modalBody{
  background-color: white;
}
.modalp{
  color:black; text-align:center;
}
.model-logo{
  width: 200px;
  height: 200px;
  margin-left: 140px;
}
.modalfooter{
  background-color: #fb9120;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}

/* Top bar for Responsive */
.topbar {
  width: 100%;
  height: 30px;
  background-color: 
#48AFFF;
  display: none;
  position: relative;
  align-items: center;
  padding: 10px;
  font-size: 12px;
  font-family: "Segoe UI";
}

.topbar .call {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
}

.topbar a {
  color: white;
}

/* Main Header or Navbar */
.navbar {
  font-family: "Segoe UI";
  width: 100%;
  height: 75px;
  display: flex;
  margin: 0;
  flex-direction: row;
  background-color: white;

}

/* Logo CSS */
.logo {
  display: flex;
  width: 30%;
  justify-content: flex-start;
  align-items: center;
  padding-left: 70px;
  margin-top:-23px;
}

.logo img {
  width: 80px;
  height: 90px;
  transition: 1s;
  margin-left: 50px;
  cursor: pointer;
}

.logo img:hover {
  transition: 1s;
  transform: rotate(360deg);

}

.logo h2 {
  font-size: 36px;
  font-weight: bold;
  text-transform: uppercase;
  color: #484848;

}

.logo h4 {
  font-size: 16px;
  color: 
#48AFFF;
  font-weight: bold;
  text-transform: uppercase;
}

/* Search bar CSS */
.search-bar {

  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 10px;
}
.search-bar{
  width: 500px;
}

.search-bar input:focus {
  box-shadow: 0 0 6px 0 #484848;
  border-color: 
#48AFFF;
  
  outline: none;
  background-color: white;


}

.search-bar input {
  width: 80%;
  border: 3px solid 
#48AFFF;
  border-radius: 20px;
  padding: 9px 10px 9px 40px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;

  /* background: transparent url("./../../assets/search.png") no-repeat 5px center; */

}

.search-container {
  width: 80%;
  position: absolute;
  top: 85px;
  left: 36%;
  z-index: 1;
  background: white;
  width: 515px;

}

.search-container .search-product-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgba(232, 233, 235, 0.7);
}

.search-product-container .search-imag {
  width: 18%;
}

.search-product-container .search-imag img {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-product-container .item-desc {
  color: black;

  font-family: "Segoe UI";
  font-size: 12px;
  /* font-weight: 500; */
  padding: 8px 8px 8px 10px;
}

/* social Media or icon Part */
.social-media {
  width: 30%;
  padding-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-media ul {
  display: flex;
  align-items: center;
  text-align: center;
}

.social-media ul li {
  font-size: 2.5rem;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}


.my-account {
  color: white;
  cursor: pointer;
}

.login-container {
  position: absolute;
  top: 50px;
  right: 0px;

}

.your-favorite {
  color: white;
  cursor: pointer;
  position: relative;
}

.your-cart {
  color: white;
  cursor: pointer;

}

.viewcart_container {
  position: absolute;
  top: 50px;
  right: 0px;
}

.my-cart {
  position: relative;
}

.my-favorite {
  position: relative;
}



.my-favorite .batch1, .my-cart .batch2 {
  position: absolute;
  right: -15px;
  top: -15px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 50%;
  font-size: 10px;
  color: #FFF;
  background-color: #48AFFF;
}

.accountnamedisplay {
  display: none;
  justify-content: center;
  color: #03569c;
}

.accountnamedisplay:active {
  color:#48afff;
}

.social-media h6 {
  font-size: 12px;
  color: #03569c;
  text-align: center;
}



/* Main Menu Naverbar */
.main-menu {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color:#48afff;
padding-top: 10px;
padding-bottom: 10px;
  align-items: center;
  text-transform: capitalize;
}

.main-menu .menu_container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  color: white;
  position: relative;

  text-decoration: none;
  text-align: center;
  margin-bottom: 0%;
}


.main-menu p {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  font-size: 0.7vw;
  font-family: "Segoe UI";
  line-height: 20px;
  font-weight: bold;
  
}

.main-menu .menu_container:hover {
  background-color:#484848;
  color: white;
  transition: 0.5s;
  cursor: pointer;
  text-decoration: none;

  /* padding-top: 12px;
  padding-bottom: 15px; */
}

.main-menu .menu_container .sab_menu {
  margin-top: 30px;
  text-align: left;
  z-index: 1;
  margin-bottom: 0px;
  
}
/* .sab_menu{
  border-bottom-right-radius: 20px !important;
} */

.menu_container .sab_menu p {
  padding:8px;
  /* padding-left: 1px; */
  width: 160px;
  text-align: left;
  background-color: white;
  font-family: "Segoe UI";
  /* list-style-type: none; */
  /* color: rgba(69, 74, 84, 0.7); */
  /* border-bottom: 1px solid rgba(69, 74, 84, 0.7); */
  color:rgb(61, 58, 58);
  font-size: 16px;
  font-weight: 610;
  z-index: 1;
  margin-bottom: 0px;

}

.menu_container .sab_menu p:hover {
  color: white;
  background-color: #48AFFF;
  border-radius: 20px;
  cursor: pointer;

}
.sab_menu
{
  padding:0px;
  margin:0px;
}


/* Main Menu2 for Responsive */
.main-menu2 {
  width: 100%;
  display: none;
  align-items: center;
  /* background-color: #2d3194; */
  padding: 10px 20px;

}

/* for Responsive  */
.search-bar2 {

  display: flex;
  justify-content: center;
  align-items: center;

}

.search-bar2 input:focus {
  box-shadow: 0 0 3px 0 #484848;
  border-color: #484848;
  outline: none;
  background-color: white;

}

.search-bar2 input {
  width: 80%;
  border: 1px solid white;
  border-radius: 20px;
  padding: 9px 10px 9px 40px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat 13px center;

}


/* Social media2 for responsive  */
.social-media2 {
  display: flex;
  font-size: 20px;
  flex-direction: row;
  position: absolute;
  right: 20px;
  top: 10px;
}

.social-media2 .mobile_icons .mobile_icon{
  padding: 10px 20px 0 20px;
}

/* Hambargar  */
.hamb {
  position: relative;
  background-color:"white";
  padding: 5px 8px;
  border-radius: 4px;

}

.ham-display {
  width: 130px;
  position: absolute;
  left: -20px;
  top: 42px;
  margin-left: 20px;
  background-color: white;
  font-family: "Segoe UI";
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
  color: rgba(69, 74, 84, 0.7);
  z-index: 1;
  /* animation-name: hamdisplay;
  animation-duration: 4s; */
}

/* @keyframes hamdisplay {
  from {
  
  width: 0px;
  height: 0px;
  }
  
  to {
  width: 200px;
  height: 600px;
  }
} */

.ham-not-display {
  display: none;
}

.ham-display p {
  color: rgba(69, 74, 84, 0.7);
  width: 100%;
  font-family: "Segoe UI";
  font-size: 12px;
  font-weight: 500;
  padding: 8px 8px 8px 14px;
  border-bottom: 1px solid rgba(69, 74, 84, 0.7);
}

hr {
  display: none;
}



/* Start Media Query  */

@media screen and (min-width: 1536px) and (max-width: 2000px) {
  .logo img {
    width: 95px;
    height: 90px;
  }
  .mLogo{
    display: none;
  }
  .modal-content{
    margin-left: 600px;
  }

  .logo h2 {
    font-size: 35px;
  }

  .main-menu p {
    font-size: 12px;
    padding: 0 5px;
  }

  .menu_container .sab_menu p {
    font-size: 12px;
  }

  .search-container {
    position: absolute;
    top: 83px;
    left: 36%;
    z-index: 1;
    background: white;
    width: 515px;
  }

}


@media screen and (min-width: 1399px) and (max-width: 1535px) {
  .logo img {
    width: 95px;
    height: 90px;
    
  }
  .mLogo{
    display: none;
  }
  .modal-content{
    margin-left: 550px;
  }
  .logo h2 {
    font-size: 30px;
  }

  .main-menu .menu_container {

    padding: 8px 5px;
  }

  .main-menu p {
    font-size: 12px;
    padding: 0 5px;
  }


  .menu_container .sab_menu p {
    font-size: 12px;
  }

  .main-menu .menu_container .sab_menu {
    margin-top: 28px;
  }

  .search-container {
    position: absolute;
    top: 83px;
    left: 36%;
    z-index: 1;
    background: white;
    width: 400px;
  }

}
@media screen and (min-width: 800px) and (max-width: 1398px){
  .search-bar {

    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 10px;
  
  }
  #sch{
    width: 30%;
  }
}
@media screen and (min-width: 300px) and (max-width: 799px){
  .search-bar {

    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-width: 10px;
  
  }
  #sch{
    width: 60%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1398px) {
  .logo {
    padding-left: 15px;
  
  }
 
  .mLogo{
    display: none;
  }

  .logo h2 {
    font-size: 23px;
  }

  .logo img {
    width: 78px;
    height: 85px;
  }
  .main-menu .menu_container {

    padding: 5px 10px;
  }

  .main-menu p {
    font-size: 12px;
    padding: 0 5px;
  }

  .menu_container .sab_menu p {
    font-size: 12px;
  }

  .main-menu .menu_container .sab_menu {
    margin-top: 44px;
  }

  .search-container {
    position: absolute;
    top: 83px;
    left: 36%;
    z-index: 1;
    background: white;
    width: 350px;
  }

}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .logo {
    padding-left: 15px;
  
  }
  .mLogo{
    display: none;
  }
  .modal-content{
    margin-left: 300px;
  }

  .logo h2 {
    font-size: 23px;
  }

  .logo img {
    width: 78px;
    height: 85px;
  }

  .search-container {
    position: absolute;
    top: 83px;
    left: 36%;
    z-index: 1;
    background: white;
    width: 300px;
  }

  .social-media {
    padding-right: 5px;
  }

  .social-media ul li {
    font-size: 2rem;
  }

  .my-favorite .batch1, .my-cart .batch2 {
    right: -11px;
    top: -11px;
    width: 15px;
    height: 15px;
    line-height: 16px;
    font-size: 10px;
  }

  .main-menu .menu_container {

    padding: 5px 5px;
  }

  .main-menu p {
    font-size: 12px;
    padding: 0 5px;
  }

  .main-menu .menu_container .sab_menu {
    margin-top: 25px;
  }

  .menu_container .sab_menu p {
    font-size: 12px;
  }


}


@media screen and (min-width: 900px) and (max-width: 1023px) {
  .logo {
    padding-left: 15px;
    margin-top:5px;
  }
  .mLogo{
    display: none;
  }
  .modal-content{
    margin-left: 250px;
  }

  .logo h2 {
    font-size: 23px;
  }

  .logo img {
    width: 78px;
    height: 85px;
    margin-top:-20px;
  }

  .search-bar input {
    padding: 5px 10px 5px 40px;

  }

  .search-container {
    position: absolute;
    top: 79px;
    left: 36%;
    z-index: 1;
    background: white;
    width: 280px;
  }

  .social-media {
    padding-right: 0px;
  }

  .social-media ul li {
    font-size: 2rem;
  }

  .my-favorite .batch1, .my-cart .batch2 {
    right: -11px;
    top: -10px;
    width: 15px;
    height: 15px;
    line-height: 16px;
    font-size: 9px;
  }

  .main-menu .menu_container {

    padding: 2px 5px;
  }

  .main-menu p {
    font-size: 12px;
    padding: 0 5px;
  }

  .main-menu .menu_container .sab_menu {
    margin-top: 22px;
  }

  .menu_container .sab_menu p {
    font-size: 12px;
  }


}

@media screen and (min-width: 800px) and (max-width: 899px) {
  .logo {
    padding-left: 15px;
  
  }
  .mLogo{
    display: none;
  }
  .modal-content{
    margin-left: 200px;
  }

  .logo h2 {
    font-size: 23px;
  }

  .logo img {
    width: 78px;
    height: 85px;
    
  }

  .search-bar input {
    padding: 7px 10px 7px 40px;
  }

  .search-container {
    position: absolute;
    top: 79px;
    left: 36%;
    z-index: 1;
    background: white;
    width: 230px;
  }

  .social-media {
    padding-right: 0px;
  }

  .social-media ul li {
    font-size: 1.7rem;
  }

  .social-media h6 {
    font-size: 11px;
  }

  .my-favorite .batch1, .my-cart .batch2 {
    right: -11px;
    top: -12px;
    width: 15px;
    height: 15px;
    line-height: 16px;
    font-size: 9px;
  }

  .main-menu .menu_container {

    padding: 0 3px;
  }

  .main-menu p {
    font-size: 11px;
    padding: 0 5px;
  }

  .main-menu .menu_container .sab_menu {
    margin-top: 20px;
  }

  .menu_container .sab_menu p {
    font-size: 11px;
  }
}

@media screen and (min-width: 600px) and (max-width: 799px), (min-width: 480px) and (max-width: 599px),
(min-width: 400px) and (max-width: 479px), (min-width: 320px) and (max-width: 399px) {

  .topbar {
    display: block;
  }
  /* .modal-content{
    margin-left:140px ;
  } */
 .mobile_icons{
  padding:5px;
 }

  .navbar {
    height: 100px;
    background-color: black;
    /* margin-bottom: 50px; */
  }

  .mLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0;
    
  }
 .logo{
  display: none;
 }

  /* .logo img {
    width: 97px;
    height: 101px;
    margin-left: -15px;
    margin-top: 25px;
   
  } */

  .logo h2, h4 {
    display: none;
  }

  .search-bar {
    display: none;
  }

  .search-bar2 input {
    margin-left: 10px;
    width: 250px;
    padding: 7px 10px 7px 40px;
  }
  ::placeholder {
    color: white;
   
  }
  .search-container {
    position: absolute;
    top: 169px;
    left: 9%;
    z-index: 1;
    background: white;
    width: 215px;
  }

  .search-product-container .item-desc {
    font-size: 11px;
  }

  .social-media {
    display: none;
  }

  .social-media-desktop {
    display: none;
  }
  .mobile_icon
  {display: none;}
  .batch1, .my-cart .batch2 {
    right: -11px;
    top: -12px;
    width: 15px;
    height: 15px;
    line-height: 16px;
    font-size: 9px;

    
  }
  

  .login-container {
    position: absolute;
    top: 17px;
    right: -30px;
  }

  .viewcart_container {
    top: 20px;
    right: -22px;
  }

  .main-menu {
    display: none;
  }

  hr {
    display: block;
  }

  .main-menu2 {
    display: flex;
    position: relative;
    width: 100%;
    background-color: #48afff;
  }
}

@media screen and (min-width: 480px) and (max-width: 599px) {

  .social-media2 {
    display: flex;
    font-size: 20px;
    flex-direction: row;
    position: absolute;
    right: 20px;
    top: 8px;
  }
  
  .model-logo{
    width: 150px;
    height: 150px;
    margin-left: 100px;
  }
  .mobile_icon
  {display: none;}

 .mobile_icons  {
    padding: 10px 15px 0 15px;
  }

  .search-bar2 input {
    margin-left: 10px;
    width: 280px;
    padding: 7px 10px 7px 40px;
  }

  .search-container {
    position: absolute;
    top: 169px;
    left: 11%;
    z-index: 1;
    background: white;
    width: 190px;
  }

  .search-product-container .item-desc {
    font-size: 11px;
  }

  .logo img {
    width: 97px;
    height: 101px;
    margin-left: -15px;
    margin-top: 25px;
  }
}

@media screen and (min-width: 400px) and (max-width: 479px) {

  .social-media2 {
    display: flex;
    font-size: 20px;
    flex-direction: row;
    position: absolute;
    right: 20px;
    top: 8px;
  }
  .modal-content{
    margin-left: 25px;
    width:350px;
  }
  .model-logo{
    width: 150px;
    height: 150px;
    margin-left: 100px;
  }
   .mobile_icon
  {display: none;}

  .mobile_icons {
    padding: 10px 12px 0 12px;
  }

  .search-bar2 input {
    margin-left: 10px;
    width: 250px;
    padding: 7px 10px 7px 35px;
  }

  .login-container {
    position: absolute;
    top: 21px;
    right: -50px;
  }

  .search-container {
    position: absolute;
    top: 169px;
    left: 12%;
    z-index: 1;
    background: white;
    width: 190px;
  }

  .search-product-container .item-desc {
    font-size: 11px;
  }

  .logo img {
    width: 97px;
    height: 101px;
    margin-left: -10px;
    margin-top: 25px;
  }
}

@media screen and (min-width: 350px) and (max-width: 399px), (min-width: 320px) and (max-width: 349px) {

  .social-media2 {
    display: flex;
    font-size: 20px;
    flex-direction: row;
    position: absolute;
    right: 20px;
    top: 8px;
  }
  /* .modal-content{
    margin-left: 7px ;
    width:350px;
} */
.model-logo{
  width: 150px;
  height: 150px;
  margin-left: 100px;
}
  .model-logo{
    width: 150px;
    height: 150px;
    margin-left: 100px;
  }
  .mobile_icon
  {display: none;}

   .mobile_icons {
    padding: 10px 10px 0 10px;
  }

  .search-bar2 input {
    margin-left: 10px;
    width: 190px;
    padding: 7px 10px 7px 30px;
  }

  .login-container {
    position: absolute;
    top: 22px;
    right: -57px;

  }

  .logo img {
    width: 67px;
    height: 71px;
    margin-left: -10px;
    margin-top:20px;
  }
}

@media screen and (min-width: 320px) and (max-width: 349px) {
  .search-bar2 input {
    margin-left: 5px;
    width: 185px;
    padding: 7px 10px 7px 35px;
    font-size: 10px;
  }
  .modal-content{
    margin-left:4px ;
    width:328px;
  }
  #confirm-btn{
    font-size: 12px;
  }
  .model-logo{
    width: 120px;
    height: 120px;
    margin-left: 100px;
  }
  .mobile_icon
  {display: none;}
  .social-media2 .mobile_icons {
    padding: 10px 8px 0 8px;
  }

  .login-container {
    position: absolute;
    top: 20px;
    right: -65px;
  }
}
@media screen and (min-width: 350px) and (max-width: 375px)
{
  .modal-content{
    margin-left:16px ;
    width:328px;
  }
  #confirm-btn{
    font-size: 12px;
  }
  .model-logo {
    width: 120px;
    height: 120px;
    margin-left: 100px;
}
}
@media screen and (min-width: 375px) and (max-width: 400px)
{
  .modal-content{
    margin-left:35px ;
    width:328px;
  }
  #confirm-btn{
    font-size: 12px;
  }
  .model-logo {
    width: 120px;
    height: 120px;
    margin-left: 100px;
}
}
@media screen and (min-width: 400px) and (max-width: 480px)
{
  .modal-content{
    margin-left:50px ;
    width:328px;
  }
  #confirm-btn{
    font-size: 12px;
  }
  .model-logo {
    width: 120px;
    height: 120px;
    margin-left: 100px;
}
}
@media screen and (min-width: 480px) and (max-width: 550px)
{
  .modal-content{
    margin-left:72px ;
    width:350px;
  }
}
@media screen and (min-width: 550px) and (max-width: 600px)
{
  .modal-content{
    margin-left:92px ;
    width:350px;
  }
  .mobile_icons  {
    padding: 10px 15px 0 15px;
  }
}
@media screen and (min-width: 600px) and (max-width: 650px)
{
  .modal-content{
    margin-left:152px ;
    width:350px;
  }
  .model-logo {
    width: 150px;
    height: 150px;
    margin-left: 100px;}
    .mobile_icons  {
      padding: 10px 15px 0 15px;
    }
}
@media screen and (min-width: 650px) and (max-width: 700px)
{
  .modal-content{
    margin-left:160px ;
    width:350px;
  }
  .model-logo {
    width: 150px;
    height: 150px;
    margin-left: 100px;}
    .mobile_icons  {
      padding: 10px 15px 0 15px;
    }
}
@media screen and (min-width: 700px) and (max-width: 800px)
{
  .modal-content{
    margin-left:170px ;
    width:400px;
  }
  .model-logo {
    width: 150px;
    height: 150px;
    margin-left: 110px;}
    .mobile_icons  {
      padding: 10px 15px 0 15px;
    }
}