*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;

}
.load-More{
    width: 200px;
    border-radius: 5px;
    height: 40px;
    background-color: #48afff;
    color: white;
    margin: 10px;
    margin-left: 40%;
    font-style: normal;
    text-align: center;
    align-items: center;
    padding: 11px;
    font-size: 16p;

}

.productspage{
    width: 100%;
    background-color: white;
    display: flex;
    /* align-items: center;
    align-content: center; */
}
.loadd>button{
    display: block;
    text-align: center;
}
.productspage .slider-bar{
    width: 20%;
    padding-top: 50px;
    font: 400 1px/1.35 Montserrat, Helvetica Neue, Verdana, Arial, sans-serif;
    padding-left: 20px;
   
}

.slider-bar .slidebar_conatainer{    
    width: 238px;
    background-color: white;
    font-family: "Segoe UI";
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    color: rgba(69, 74, 84, 0.7);
    position: relative;
}  
.slidebar_conatainer .menu_container2{
    display: flex;
    flex-direction: row;
}
.slidebar_conatainer p{
    width: 100%;
    font-family: "Segoe UI";
    font-size: 12px;
    font-weight: 500;
    padding: 8px 8px 8px 10px;
    color: rgba(69, 74, 84, 0.7);
    border-bottom: 1px solid rgba(69, 74, 84, 0.7);

}
.slidebar_conatainer p:hover{
    color: white;
    background-color:  #48AFFF;
    cursor: pointer;
}
.slidebar_conatainer .sab_menu2{
    position: absolute;
    width: 200px;
    background-color: white;
    right: -200px;
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 50%);
    z-index: 1;
}

.productspage .products{
    width: 80%;
    /* padding-top: 50px; */
}
.products{
    overflow: hidden;
}

.products h1{
    background-color: #484848;
    text-align: center;
    color: white;
    font-size: 1.5em;
    font-weight: 500;
    padding: 10px 0 10px 0;
    display: block;
    text-transform: uppercase;
  
}

@media screen and (min-width: 320px) and (max-width: 1400px){
    .productspage .slider-bar {
        display: none;
    }
    .productspage .products {
        width: 100%;
    }
}

@media screen and (min-width: 320px) and (max-width: 799px){
    .productspage .slider-bar{
        width: 0%;   
        padding: 0;
    }
    /* .load{
        align-items: center;
        text-align: center;
    } */
    
    .slider-bar .slidebar_conatainer{    
        display: none;
    }
    .productspage .products{
        width: 100%;
    }
}

@media screen and (min-width: 500px) and (max-width: 699px)
{
    .load-More{
        margin-left: 35%;
    }
}
@media screen and (min-width: 350px) and (max-width: 499px)
{
    .load-More{
        margin-left: 25%;
    }
}
@media screen and (min-width: 250px) and (max-width: 349px)
{
    .load-More{
        margin-left: 15%;
    }
}