* {
    box-sizing: border-box;
    font-family: "Jost", sans-serif;
}

.card-title {
    font-size: 50px;
}

.ariv {
    font-size: 40px;
    font-weight: 500;
    color:
#48afff;
    margin-top: 10px;
}



/* .about-text {
    justify-content: center;
    padding-bottom: 100px;
    padding-top: 100px;
    font-size: 16px;
    text-align: justify;
    padding-left: 70px;
    padding-right: 20px;
} */

.card-text {
    font-size: 20px;
}

#SecRow {
    background-image: url("https://thevapehouse.pk/Images/Banners/about2.jpg");
    background-size: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;


    /* display:block; */
}

.aboutus {
    background-color: #f4eddd;
    justify-content: center;
    display: block;
    justify-content: center;
    align-items: center;


}

.aboutus img {
    width: 100%
}

.aboutus .aboutus-p {
    /* margin: 20px 20px; */
    font-size: 14px;
    padding: 10px 25% 10px 25%;
    text-align: justify;
}

/* About Us, Privacy Policy, Terms & Conditions, Delivery Information css */
.mainhadding {
    background-color: #f4eddd;
    padding: 20px 9% 20px 9%;
    display: flex;
    justify-content: center;
    line-height: 30px;

}

h1 {
    /* text-align: left; */
    margin-bottom: 20px;
    color: 
#48afff;
    padding: 10px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    /* font-size: 7vh; */

}

.mainhadding h1 {
    text-align: center;
    background-color: 
#48afff;
    color: white;
    padding: 10px;
    margin: 20px;
    font-family: 'Roboto Condensed', sans-serif;
    font-weight: 700;
    font-size: 16px;
}

.mainhadding li {
    text-align: justify;
    margin: 20px;
    font-size: 14px;
    list-style-type: none;
}

.bullet li {
    list-style-type: circle;
}


/* Contact Us CSS */

.alldata {
    width: 100%;
    /* margin: 30px; */
    box-sizing: border-box;
    display: flex;


}

.alldataa {
    width: 100%;
    /* margin: 30px; */
    /* box-sizing: border-box;
    display: flex; */


}

.alldata .forms {
    width: 67%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 20px;
    background-color: white;
}

.alldata .forms-a {
    text-align: center;
    width: 100%;
    font-size: 1vw;
    margin: 20px;


}

.alldata .forms-b {
    width: 100%;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.forms .forms-b input {
    width: 40%;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #E4E7ED;
    font-size: 14px;
}

.forms-b input:focus {
    box-shadow: 0 0 3px 0 #038eff;
    border: 
#48afff;
    outline: none;

}

.forms textarea {
    width: 90%;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #E4E7ED;
    padding: 10px;
    margin: 10px;
    font-size: 14px;
}

.forms textarea:focus {
    box-shadow: 0 0 3px 0 #038eff;
    border: 
#48afff;
    outline: none;

}

.forms .btn {
    text-align: center;
    margin: 30px;

}

.forms .btn button {
    background-color: 
#48afff;
    color: white;
    width: 150px;
    height: 40px;
    cursor: pointer;
    border-radius: 30px;
    border: none;
}

.alldata .info {
    width: 33%;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 50px 20px 50px 20px;
    display: block;
    margin-left: 20px;
    border-radius: 20px;
    background-color: white;

}


.info h1 {
    color: solid black;
    font-size: 2vw;
    margin-bottom: 5px;
    margin-top: -25px;
}

.info h2 {
    color: red;
    font-size: 1.5vw;
    margin: 10px 0 3px 0;
}

.info p {
    font-size: 1.2vw;
    margin: 10px 0 3px 0;
}

/* .info h3 {

    font-size: 1.2vw;
    margin: 20px 0 3px 0;
} */
.headingc {
    width: 100%;
}

.pakheading {
    font-size: 25px !important;
}

.mainhadding .pakheading .info p {

    font-size: 1vw;
    color: black;
    margin-bottom: 2px;

}

.google1-map {
    margin-top: 10px 0px 0px 10px;
    padding-top: 40px;
    width: 100%;

}

.google-map {
    display: flex;
    flex-direction: row;
    margin: 30px 0 0 0;
}

.google-map .google-map1 {
    width: 100%;

}

.google-map .google-map2 {
    width: 100%;

}

.alldataa .formss {
    /* width: 67%; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 20px;
    background-color: white;
    margin: 2%;
}

.alldataa .forms-aa {
    text-align: center;
    /* width: 100%; */
    font-size: 1vw;
    margin: 0px;
    margin-top: 20px;


}

.alldataa .forms-bb {
    /* width: 100%; */
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.formss .forms-bb input {
    width: 90%;
    margin: 10px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #E4E7ED;
    font-size: 14px;
}

.forms-bb input:focus {
    box-shadow: 0 0 3px 0 #038eff;
    border: 
#48afff;
    outline: none;

}

.formss textarea {
    width: 90%;
    height: 100px;
    border-radius: 5px;
    border: 1px solid #E4E7ED;
    padding: 10px;
    margin: 10px;
    font-size: 14px;
}

.formss textarea:focus {
    box-shadow: 0 0 3px 0 #038eff;
    border: 
#48afff;
    outline: none;

}

.formss .btn {
    text-align: center;
    margin: 30px;

}

.formss .btn button {
    background-color: 
#48afff;
    color: white;
    width: 150px;
    height: 40px;
    cursor: pointer;
    border-radius: 30px;
    border: none;
}



.infoo h1 {
    color: solid black;
    margin-bottom: 5px;
    margin-top: -25px;
}

.infoo h2 {
    color: red;
    margin: 10px 0 3px 0;
}

.infoo p {
    margin: 10px 0 3px 0;
}

/* .info h3 {

    font-size: 1.2vw;
    margin: 20px 0 3px 0;
} */


.infoo p {

    color: black;
    margin-bottom: 2px;
    font-size: 14px;
}

.alldataa .infoo {
    /* width: 100%; */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 50px 20px 50px 20px;
    display: block;
    /* margin-left: 30px; */
    /* margin-top: 30px; */
    border-radius: 20px;
    margin: 2%;
    background-color: white;

}

@media screen and(min-width: 321px) and (max-width: 420px) {
    .btn button {

        width: 200px;
        height: 40px;
    }
}

@media screen and(min-width: 421px) and (max-width: 520px) {
    .btn button {

        width: 300px;
        height: 40px;
    }
}

@media screen and(min-width: 521px) and (max-width: 620px) {
    .btn button {

        width: 400px;
        height: 40px;
    }
}

@media screen and(min-width: 766px) and (max-width: 2000px) {
    .alldataa .infoo {
        /* width: 100%; */
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        padding: 50px 20px 50px 20px;
        display: block;
        margin-left: 30px;
        /* margin-top: 30px; */
        border-radius: 20px;
        background-color: white;

    }

    .infoo h1 {
        color: solid black;
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: -25px;
    }

    .infoo h2 {
        color: red;
        font-size: 15px;
        margin: 10px 0 3px 0;
    }

    .infoo p {
        font-size: 12px;
        margin: 10px 0 3px 0;
    }

    /* .info h3 {

    font-size: 1.2vw;
    margin: 20px 0 3px 0;
} */


    .infoo p {

        font-size: 10px;
        color: black;
        margin-bottom: 2px;

    }
}

@media screen and(min-width: 300px) and (max-width: 766px) {
    .infoo h1 {
        color: solid black;
        font-size: 20px;
        margin-bottom: 5px;
        margin-top: -25px;
    }

    .infoo h2 {
        color: red;
        font-size: 15px;
        margin: 10px 0 3px 0;
    }

    .infoo p {
        font-size: 16px;
        margin: 10px 0 3px 0;
    }

    /* .info h3 {
    
        font-size: 1.2vw;
        margin: 20px 0 3px 0;
    } */


    .infoo p {

        font-size: 10px;
        color: black;
        margin-bottom: 2px;

    }
}

@media screen and(min-width: 481px) and (max-width: 1080px) {
    .alldata {
        width: 100%;
        /* padding: 10px; */
        margin: 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    .alldataa {
        width: 100%;
        /* padding: 10px; */
        margin: 0;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }

    .about-text {
        justify-content: center;
        padding-bottom: 70px;
        padding-top: 70px;
        font-size: 16px;
        text-align: justify;
        padding-left: 50px;
        padding-right: 50px;
    }

    .alldata .forms {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    .alldataa .formss {
        width: 100%;
        padding: 0px;
        margin: 0px;
    }

    .alldata .forms-a {
        text-align: center;
        width: 100%;
        font-size: 2vw;
        margin: 20px;
    }

    .alldataa .forms-aa {
        text-align: center;
        width: 100%;
        font-size: 2vw;
        margin: 20px;
    }

    .alldata .info {
        width: 100%;
        padding: 50px;
        display: block;
        margin: 20px 0 0 0;
    }

    .alldataa .infoo {
        width: 100%;
        padding: 50px;
        display: block;
        margin: 20px 0 0 0;
    }

    .alldata .forms-b {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
    }

    .alldataa .forms-bb {
        width: 100%;
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-around;
    }

    .forms .forms-b input,
    .forms .forms-b textarea {
        width: 90%;
        margin: 5px;
    }

    .formss .forms-bb input,
    .formss .forms-bb textarea {
        width: 90%;
        margin: 5px;
    }

    .info h1 {
        font-size: 4vw;
    }

    .info h2 {
        font-size: 14px;
    }

    .info h3 {
        font-size: 16px;
    }

    .info p {
        font-size: 14px;
    }

    .infoo h1 {
        font-size: 4vw;
    }

    .infoo h2 {
        font-size: 14px;
    }

    .infoo h3 {
        font-size: 16px;
    }

    .infoo p {
        font-size: 14px;
    }

}

@media screen and (min-width: 481px) and (max-width: 799px) {

    .forms-b>input:enabled::-webkit-input-placeholder {
        color: #323533;
        opacity: 0.7;
    }

    .forms-bb>input:enabled::-webkit-input-placeholder {
        color: #323533;
        opacity: 0.7;
    }

    h1 {
        padding: 10px;
        margin: 20px;
        font-size: 20px;
        text-align: center;
    }


    .google-map {
        display: flex;
        flex-direction: column;
    }

    .google-map .google-map1 {
        padding-right: 0;
        margin-bottom: 15px;
    }

    .google-map .google-map2 {
        padding-left: 0;
        margin-top: 15px;
    }

    .forms-b>input:enabled::-webkit-input-placeholder {
        color: #323533;
        opacity: 0.7;
    }

    .forms-bb>input:enabled::-webkit-input-placeholder {
        color: #323533;
        opacity: 0.7;
    }
}

@media screen and (min-width: 320px) and (max-width: 479px) {
    .aboutus .aboutus-p {
        padding: 10px 10% 10px 10%;
    }

    .alldataa .formss {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        border-radius: 20px;
        background-color: white;
        flex-direction: row;
    }

    .alldataa .infoo {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        padding: 50px 20px 50px 20px;
        display: block;
        margin-top: 10px;
        margin-left: 0px;
        border-radius: 20px;
        background-color: white;
        flex-direction: row;

    }

    .alldataa .infoo {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        padding: 50px 20px 50px 20px;
        display: block;
        margin-left: 0px;
        margin-top: 20px;
        border-radius: 20px;
        background-color: white;


    }

    .about-text {
        justify-content: center;
        padding-bottom: 7px;
        padding-top: 7px;
        font-size: 12px;
        text-align: justify;
        padding-left: 5px;
        padding-right: 5px;
    }

    .mainhadding h1 {
        /* text-align: center;
        background-color: 
#48afff;
        color: white;
        padding: 5px;
        margin: 10px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 500; */
        font-size: 14px;
    }

    h1 {
        padding: 5px;
        margin: 10px;
        font-size: 14px !important;
        text-align: center;
        /* margin-left: -40px; */
    }

    .forms .btn button {
        align-items: center !important;
        width: 195px;
        height: 50px;

    }

    .formss .btn button {
        align-items: center !important;
        width: 195px;
        height: 50px;

    }

    .forms-b>input:enabled::-webkit-input-placeholder {
        color: #323533;
        opacity: 0.7;
    }

    .forms-bb>input:enabled::-webkit-input-placeholder {
        color: #323533;
        opacity: 0.7;
    }

    .mainhadding h1 {
        text-align: center;
        background-color: 
#48afff;
        color: white;
        padding: 5px;
        margin: 10px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 500;
        font-size: 16px;
        margin-left: 0px;
    }

    .mainhadding {
        background-color: #f4eddd;
        padding: 20px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }

    .google-map {
        display: flex;
        flex-direction: column;
    }

    .google-map .google-map1 {
        padding-right: 0;
        margin-bottom: 15px;
    }

    .google-map .google-map2 {
        padding-left: 0;
        margin-top: 15px;
    }

    #welcom {
        font-size: 25px !important;
        margin: 30px;
        font-family: Roboto, sans-serif;
        font-weight: 600;
        text-align: center;
    }

    .about-text {
        justify-content: center;
        padding: 20px;

        font-size: 16px;
        text-align: justify;

    }

    .about {
        background-color: black;
    }

    .ariv {
        font-size: 30px;
        font-weight: 500;
        color:
#48afff;
        margin-top: 10px;
    }

    .citi {
        font-size: 10px;

    }

}

@media screen and (min-width: 220px) and (max-width: 320px) {

    .mainhadding h1 {
        text-align: center;
        background-color: 
#48afff;
        color: white;
        padding: 5px;
        margin: 10px;
        font-family: 'Roboto Condensed', sans-serif;
        font-weight: 500;
        font-size: 12px;
        margin-left: 0px;
    }

    .citi {
        font-size: 10px;
    }

    .mainhadding {
        background-color: #f4eddd;
        padding: 20px 0px 0px 0px;
        display: flex;
        justify-content: center;
        margin-left: 0px;
    }

    #welcom {
        font-size: 20px;
        margin: 20px;
        font-family: Roboto, sans-serif;
        font-weight: 600;
        text-align: center;
    }

    .about-text {
        justify-content: center;
        padding: 10px;
        font-size: 12px;
        text-align: justify;
        padding-bottom: 7px;
        padding-top: 77px;
        font-size: 12px;
        padding-left: 5px;
        padding-right: 5px;

    }

    .about {
        background-color: black;
    }

    .ariv {
        font-size: 20px;
        font-weight: 300;
        color:
#48afff;
        margin-top: 6px;
    }

    .citi {
        font-size: 10px;

    }


    .pakheading {

        color: #069342;
        font-family: Roboto, sans-serif;
        font-size: 30px;
        font-weight: 600;
        text-align: center;
    }

    .textt {
        font-size: 13px;
    }
}

@media screen and (min-width: 481px) and (max-width: 2000px) {
    .about-text {
        justify-content: center;
        padding-bottom: 70px;
        padding-top: 70px;
        font-size: 16px;
        text-align: justify;
        padding-left: 50px;
        padding-right: 50px;
    }

    .citi {

        font-size: 20px;

    }

    #welcom {
        font-size: 35px;
        margin: 30px;
        font-family: Roboto, sans-serif;
        font-weight: 600;
        text-align: center;
    }
}


#welcom {
    font-size: 4vw;
    margin: 30px;
    font-family: Roboto, sans-serif;
    font-weight: 600;
    text-align: center;
}