* {
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}



.dropdown {
    float: left;
    overflow: hidden;
}

.dropdown .dropbtn {
    font-size: 16px;
    border: none;
    outline: none;
    color: white;
    padding: 14px 16px;
    background-color: inherit;
    font-family: inherit;
    /* Important for vertical align on mobile phones */
    margin: 0;
    /* Important for vertical align on mobile phones */
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
    float: none;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
}

.btnVriation {
    border-radius: 7px;
    text-align: center;
    justify-content: center;
    color: white;
    padding-top: 8px;
    font-size: 16px;
    background-color: #48afff;
    width: 200px;
    height: 40px;
}

.btnVriation:hover {
    color: white;
}

.btnV {
    border-radius: 7px;
    text-align: center;
    justify-content: center;
    color: white;
    padding-top: 8px;
    font-size: 16px;
    background-color:#48afff;
    width: 200px;
    height: 40px;
}

.btnV:hover {
    color: white;
}

.dropDownMenu {
    height: 25%;
    width: 50%;
    /* font-weight: bold; */
    font-size: 16px;
    cursor: pointer;
    position: relative;
    border: 0.2px solid;
    border-color: #48afff;
    padding: 7px;
    border-radius: 8px;
    margin: 10px;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    background-color: white;



}

.drop-down-menu_pops {
    width: 280px;
    z-index: 1;
    justify-content: center;
    overflow: hidden;
    border: 0.2px solid;
    border-color: #48afff;
    margin-left: 15px;
    margin-top: -10px;
    position: absolute;
    padding-left: 10px;
    flex-direction: column;
    background-color: #f7f7f7;
}

.dropdown-pops:hover {
    background-color:#48afff;
    color: white;
    border-color: rgb(236, 230, 230);

}

.dropdown4 {
    /* height: 20px; */
    width: 95%;
    /* margin: 3px; */
    padding: 6px;
    margin-bottom: 0px;
    font-size: 16px;
    text-align: center;
    /* font-weight: bold; */
    cursor: pointer;


    justify-content: center;
    border-bottom: 1px solid;
    border-color: #48afff;
}

.dropdown-content a:hover {
    background-color: #ddd;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}


.p-detail {
    width: 100%;
    background-color: #f7f7f7;
    padding: 40px 20px;
    display: flex;
}

.p-detai {
    display: flex;
    border: 1px solid;
    height: fit-content;
    padding: 5px;
    border-radius: 5px;
    margin: 1px;
    cursor: pointer;
}

.p-img img {
    width: 50px;
    height: 70px;
}





.p-picture {
    width: 100%;
    height: 60%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;

}

.p-picture img {
    width: 40%;
    transition: transform .2s;
    margin: 0 auto;
    padding: 20px;
    height: auto;
}



.p-disc {
    width: 50%;
    padding: 40px 0 40px 40px;
}

.p-disc h2 {
    text-transform: capitalize !important;
    font-size: 28px;
    font-weight: bold;
    margin-top: 20px;
}

.p-disc h3 {
    display: inline-block;
    font-size: 40px;
    margin: 10px 0 10px 0;
    color: #48AFFF;
    display: block;

}

.p-disc h3 .oldPrice {
    font-size: 16px;
    color: #8D99AE;
    margin-left: 10px;
}

.p-disc .product-name {
    display: inline-block;
    font-size: 24px;
    margin: 10px 0 10px 0;
    display: block;
}

.cart-size {
    display: inline-block;
    font-size: 14px;
    margin: 10px 0 10px 0;
    display: block;
}

.qty {
    display: flex;
    align-items: center;

}

.qty p {
    padding: 10px;
    font-size: 12px;
    font-weight: bold;

}

.qty .qty-main {
    display: flex;
    height: 28px;
    width: 100px;
    margin: 20px;
    margin-left: 0;
    justify-content: center;
    align-items: center;


}

.qty-first {


    width: 30px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}

.qty-main .plus-a {
    background-color: #ebebeb;
    border-radius: 5px;
}

.qty-main .minas-a {
    background-color: #ebebeb;
    border-radius: 5px;
}

.qty-main .plus-a:hover {
    background-color: #48AFFF;
}

.qty-main .minas-a:hover {
    background-color: #48AFFF;
}

.qty-main a {
    width: 25%;
    text-align: center;
    font-size: 14px;
    border: none;
    background-color: #FFF;
    padding: 6px 0 6px 0;
    color: black;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}


.qty .btn button {
    background-color: #48AFFF;
    color: white;
    width: 140px;
    height: 40px;
    cursor: pointer;
    border-radius: 28px;
    border-width: 0;
}

.qty .btn button:hover {
    background-color: #48AFFF;
    color: white;
}

.p-disc .review {
    margin-top: 20px;
    color: #48AFFF;
    font-size: medium;
    display: flex;


}

.p-disc .review a {
    font-size: 12px;
    color: #39c;
}

.p-disc .review a:hover {
    color: #48AFFF;
}

.p-disc .favorite {
    margin-top: 20px;
    color: #39c;
    font-size: medium;
    display: flex;
}

.p-disc .favorite a {
    font-size: 12px;
    color: #39c;
}

.p-disc .favorite:hover {
    color: #48AFFF;
}

.p-disc .favorite a:hover {
    color: #48AFFF;
}

.p-disc .category {
    margin-top: 20px;
    font-size: 14px;
    display: flex;
}

.p-disc .category a {
    font-size: 12px;
    color: #39c;
}

.p-disc .category a:hover {
    color: #48AFFF;
}

.p-disc .share {
    margin-top: 20px;
    font-size: 14px;
    display: flex;
}

.p-disc .share a {
    font-size: 18px;
    color: #39c;
}

.p-disc .share a:hover {
    color: #48AFFF;
}

.p-disc .btn1 {
    width: 100%;
    margin-top: 50px;
    font-size: 14px;
    display: flex;
    justify-content: left;
    align-items: center;
}

.p-disc .btn1 .reviews-btns {
    background-color: #48AFFF;
    color: white;
    width: auto;
    height: 40px;
    cursor: pointer;
    border-radius: 8px;
    line-height: 38px;
    border-width: 0;
    padding: 0 20px;
    margin-right: 20px;
}

.p-disc .btn1 .reviews-btns2 {
    background-color: #48AFFF;
    color: white;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 38px;
    cursor: pointer;
    border-radius: 8px;
    border-width: 0;
    padding: 0 20px;
    margin-top: 10px;
}

.p-disc .btn1 button:hover {
    background-color: #48AFFF;
    color: white;
}

.btn1 .open-review {
    position: absolute;
}

.related-product {
    padding: 0 20px 0 20px;
    background-color: #f7f7f7;


}

.related-product h1 {
    width: 100%;
    height: 35px;
    background-color: #484848;
    color: white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;

}

.product-main {
    width: 100%;
    padding-top: 20px;
    font-size: 2.5em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: #48AFFF;
    background-color: white;

}

.product_container {
    width: 25%;
    margin: 20px;
    display: flex;
    background-color: white;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.product_container:hover {
    background-color: #fffbf1;
    border: 1px solid;
    color: #48AFFF;
}


.product_container img {
    width: 100%;
}

.product_container p {
    font-size: 14px;
    margin: 10px;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.product_container h6 {
    color: rgb(0, 0, 0);
    margin-top: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (min-width: 1536px) and (max-width: 1920px) {
    .p-picture img {
        width: 900px;
        padding: 5px;
        height:500px;
    }
    /* .Vslider{
        width: "550px"
    } */
}
.vSlider{
    width: "550px"
}

@media screen and (min-width: 1399px) and (max-width: 1535px) {
    .p-picture img {
        width: 700px;
        padding: 10px;
        height:400px;
    }
    /* .Vslider{
        width: "100px"
    } */
}

@media screen and (min-width: 1200px) and (max-width: 1398px) {
    .p-picture img {
        width: 600px;
        padding: 10px;
        height:400px;
    }
    /* .Vslider{
        width: "100px"
    } */
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
    .p-picture img {
        width: 500px;
        padding: 0px;
        height:400px
    }
    .drop-down-menu_pops {
        width: 208px;
        
    }
    .Vslider{
        width: "100px"
    }
}

@media screen and (min-width: 800px) and (max-width: 1023px) {
    .p-picture img {
        width: 400px;
        padding: 17px;
    }
    .drop-down-menu_pops {
        width: 150px;
        
    }
    .Vslider{
        width: "100px"
    }
}

@media screen and (min-width: 600px) and (max-width: 799px) {
    .Vslider{
        width: "100px"
    }
    .drop-down-menu_pops {
        width: 217px;
        
    }
    .p-detail {
        display: block;
    }

    .p-picture {
        width: 100%;
    }

    .p-picture img {
        width: 100%;
        height: 500px;
    }

    .p-disc {
        width: 100%;
        padding: 70px;
    }
}

@media screen and (min-width: 480px) and (max-width: 599px) {
   .Vslider{
     width: 380px;
     margin:20px;
     
   }
   .dropDownMenu {
    height: 25%;
    width: auto;
    /* font-weight: bold; */
    font-size: 16px;
    cursor: pointer;
    position: relative;
    border: 0.2px solid;
    border-color: #48AFFF;
    padding: 7px;
    border-radius: 8px;
    margin: 10px;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    background-color: white;



}
    .p-detail {
        display: block;
    }

    .p-picture {
        width: 100%;
    }

    .p-picture img {
        width: 100%;
        height: 400px;
    }

    .p-disc {
        width: 100%;
        padding: 70px 40px 40px 40px;
       
    }

}

@media screen and (min-width: 400px) and (max-width: 479px) {
    .Vslider{
        width: 350px;
        
      }
    .btnV {
        border-radius: 7px;
        text-align: center;
        justify-content: center;
        color: white;
        padding-top: 8px;
        font-size: 16px;
        background-color: #48AFFF;
        width: auto;
        height: 40px;
    }
    .dropDownMenu {
        height: 25%;
        width: auto;
        /* font-weight: bold; */
        font-size: 16px;
        cursor: pointer;
        position: relative;
        border: 0.2px solid;
        border-color: #48AFFF;
        padding: 7px;
        border-radius: 8px;
        margin: 10px;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        background-color: white;
    
    
    
    }

    .btnV:hover {
        color: white;
    }

    .p-detail {
        display: block;
    }

    .p-picture {
        width: 100%;
    }

    .p-picture img {
        width: 100%;
        height: 350px;
    }

    .p-disc {
        width: 100%;
        padding: 40px 0 0 0;
    }

    .qty .btn button {
        font-size: 12px;
        height: 40px;

    }

    .p-disc .btn1 {
        font-size: 12px;
        display: flex;
    }

    .p-disc .btn1 .reviews-btns {
        height: 40px;
        margin-bottom: 10px;
    }

    .p-disc .btn1 .reviews-btns2 {
        height: 40px;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 320px) and (max-width: 399px) {
    .Vslider{
        width: 270px;
        margin:20px;
      }
    .btnV {
        border-radius: 7px;
        text-align: center;
        justify-content: center;
        color: white;
        padding-top: 8px;
        font-size: 16px;
        background-color: #48AFFF;
        width: auto;
        height: 40px;
    }
    .dropDownMenu {
        height: 25%;
        width: auto;
        /* font-weight: bold; */
        font-size: 16px;
        cursor: pointer;
        position: relative;
        border: 0.2px solid;
        border-color: #48AFFF;
        padding: 7px;
        border-radius: 8px;
        margin: 10px;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        background-color: white;
    }
    .drop-down-menu_pops {
        width: 65%;
        z-index: 1;
        justify-content: center;
        overflow: hidden;
        border: 0.2px solid;
        border-color: #48AFFF;
        margin-left: 25px;
        margin-top: -10px;
        position: absolute;
        padding-left: 10px;
        flex-direction: column;
        background-color: #f7f7f7;
    }

    .btnV:hover {
        color: white;
    }

    .p-detail {
        display: block;
    }

    .p-picture {
        width: 100%;
    }

    .p-picture img {
        width: 100%;
        height: 300px;
    }

    .p-disc {
        width: 100%;
        padding: 40px 0 0 0;
    }

    .qty .btn button {
        font-size: 12px;
        height: 35px;

    }

    .p-disc .btn1 {
        margin-top: 30px;
        font-size: 18px;
        display: flex;
    }

    .p-disc .btn1 .reviews-btns {
        height: 35px;
        padding: 0 10px;
    }

    .p-disc .btn1 .reviews-btns2 {
        height: 35px;
        padding: 0 10px;
    }
}

.optionnn:hover {
    background-color: black;
}

@media screen and (min-width: 550px) and (max-width: 2000px)
{
    .vslider{width:550px}

}
@media screen and (min-width: 450px) and (max-width: 500px)
{
    .vslider{width:450px}

}
@media screen and (min-width: 500px) and (max-width: 550px)
{
    .vslider{width:500px}

}
@media screen and (min-width: 400px) and (max-width: 500px)
{
    .vslider{width:400px}

}
@media screen and (min-width: 300px) and (max-width: 399px)
{
    .vslider{width:300px}

}