.maind{
    justify-content:center;
    align-items: center;
    width: 100%;
    flex-direction: row;
    z-index: 0;
    display:flex

}
.main2 {
    width: 1300px;
    /* border: 1px solid; */
}